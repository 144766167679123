import React, { useState, useEffect } from 'react';
import AgreementLab from '../reqLab/AgreementLab';
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router-dom";

const hp = {
    height: "700px"
}

function RegisterLab(props) {
    const [registerResult, setRegisterResult] = useState(-1);
    const [registerLab, setRegisterLab] = useState("");
    const [teacher, setTeacher] = useState("");
    const [partition, setPartition] = useState("");
    const [destUrl, setDestUrl] = useState("");

    var history = useHistory();


    useEffect(() => {
        setTimeout(async () => {
            await fetch(process.env.REACT_APP_URL_REGISTER_LAB_BACKEND + '/' + props.handle, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    /*
                    statusRegistration: "success",
                    labName: labUpdated.labName,
                    status: labUpdated.status,
                    teachers: labUpdated.teachers,
                    teacher: myTeacher
                    */
                    if (data.statusRegistration === "success") {
                        setRegisterResult(0);
                        setRegisterLab(data.labName);
                        setTeacher(data.teacher);
                        setPartition(data.partition);
                        setDestUrl(data.destUrl);
                    } else if (data.statusRegistration === "alreadyRegistered") {
                        setRegisterResult(2);
                        setRegisterLab(data.labName);
                        setTeacher(data.teacher);
                        setPartition(data.partition);
                        setDestUrl(data.destUrl);
                    } else {
                        setRegisterResult(1);
                    }
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                    setRegisterResult(-2);
                });
        });
    }, [props.handle]);

    function handleSubmit(e) {
        e.preventDefault();
        history.push("/")
    }

    const renderRegisterPage = (status) => {
        var ret = null;
        var urlRedirect = process.env.REACT_APP_URL_REGISTER + props.handle;

        if (registerResult === -2) {
            window.location.replace(urlRedirect);
        }

        if (props.apiAuthName) {
            switch (status) {
                case 0:
                    ret = (
                        <React.Fragment>
                            <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <div style={hp}>
                                    <h1 className="welcome">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</h1>
                                    <h3>Registrazione avvenuta con successo!</h3>
                                    <br />
                                    <p>Laboratorio: {registerLab}</p>
                                    <p>Docente: {teacher}</p>
                                    <p>Turno: {partition}</p>
                                    <br />
                                    <p>La tua Macchina Virtuale sarà disponibile entro 12 ore al seguente indirizzo:
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href=
                                            {destUrl}
                                        > {destUrl}</a>
                                    </p>
                                    <AgreementLab />
                                </div>

                                <div>
                                    <br />
                                    <input type="submit" value="Torna alla Home Page" />
                                </div>
                            </Form>
                        </React.Fragment >
                    );
                    break;
                case 1:
                    ret = (
                        <React.Fragment>
                            <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <div style={hp}>
                                    <h1 className="welcome">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</h1>
                                    <h3>Registrazione fallita</h3>
                                    <br />
                                    <p>Indirizzo per l'iscrizione errato oppure le iscrizioni non sono abilitate per il corrente Laboratorio Virtuale.</p>
                                    <p>Per aprire una richiesta di assistenza clicca
                                        <a href={process.env.REACT_APP_URL_SERVICEDESK} > qui</a>
                                    </p>
                                    <br />
                                    <input type="submit" value="Torna alla Home Page" />
                                </div>
                            </Form>
                        </React.Fragment>
                    );
                    break;
                case 2:
                    ret = (
                        <React.Fragment>
                            <Form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <div style={hp}>

                                    <h1 className="welcome">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</h1>
                                    <h3>Registrazione già effettuata</h3>
                                    <br />
                                    <p>Laboratorio: {registerLab}</p>
                                    <p>Docente: {teacher}</p>
                                    <p>Turno: {partition}</p>
                                    <br />
                                    <p>La tua Macchina Virtuale sarà disponibile entro 12 ore al seguente indirizzo:
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href=
                                            {destUrl}
                                        > {destUrl}</a>
                                    </p>
                                    <AgreementLab />
                                </div>
                                <div>
                                    <br />
                                    <input type="submit" value="Torna alla Home Page" />
                                </div>

                            </Form>

                        </React.Fragment >
                    );
                    break;
                default:
            }
        } else {
            ret = null;
        }


        return ret;
    }


    return (renderRegisterPage(registerResult));
}

export default RegisterLab;
import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Can from "../Can";

function UpdateRegisteredStudents(props) {
    var ret;
    var retAlert;
    if (props.isSubmitble) {
        ret = (
            <Can
                role={props.apiAuthGroups}
                perform="labRequest-page:confirm"
                yes={() => (
                    <React.Fragment>
                        {retAlert}
                        <Button variant="primary" type="submit" >
                            {props.buttonDescription}
                        </Button>
                    </React.Fragment>
                )}
                no={() => (
                    <React.Fragment>
                        <Alert variant="warning">

                            <Alert.Heading>Funzionalità di richiesta Laboratorio Virtuale non abilitata</Alert.Heading>
                            <p>
                                La funzionalità di richiesta Laboratorio Virtuale è in corso di attivazione e sarà a breve disponibile.
                            </p>

                        </Alert>
                    </React.Fragment>
                )}
            />



        );

        if (props.statusRequestUpdRegisteredStudents === "success") {
            retAlert = (
                <React.Fragment>
                    <Alert variant="success">
                        <Alert.Heading>Elenco iscritti al Laboratorio Virtuale aggiornato con successo!</Alert.Heading>
                    </Alert>
                </React.Fragment>
            );
        } else if (props.statusRequestUpdRegisteredStudents === "error")  {
            retAlert = (
                <React.Fragment>
                    <Alert variant="danger">

                        <Alert.Heading>Si è verificato un errore!</Alert.Heading>
                        <p>
                            updstudents Contattare l'assistenza.
                        </p>
                    </Alert>
                </React.Fragment>
            );

        };

    } else {
        ret =null;
    }

    return ret;
}

export default UpdateRegisteredStudents;
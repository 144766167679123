import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select'
import SelectLib from './SelectLib';



function SelectAllocationMode(props) {
    const [allocationMode] =
        useState([
            { value: "all", label: "Tutte" },
            { value: "even", label: "Postazioni pari" },
            { value: "odd", label: "Postazioni dispari" },
            { value: "choose", label: "Postazioni selezionate" },
        ])

    const [selected, setSelected] = useState();
    
    var ret;

    const showLibSelection = () => {
        var showLibRet = null;

        if (selected != null) {
            //console.log("DEBUG students selected: " + JSON.stringify(props.students))
            console.log("debug pacchetto studenti: " + props.students.length)
            showLibRet = (
                <SelectLib
                    apiAuthResponse={props.apiAuthResponse}
                    setStudents={props.setStudents}
                    students={props.students}
                    allocationMode={selected.value}
                    labServicesVirtualmachines={props.labServicesVirtualmachines}
                />

            );
        }
        return showLibRet;
    }


    function onSelectChange(selectedOption) {
        if (selectedOption) {
            setSelected(selectedOption);
        }
    };

    ret = (
        <React.Fragment >
            <Form.Label>Allocazione postazioni esame</Form.Label>

            <Card style={{ padding: '3rem' }}>


                <Row xs={1} md={2} className="g-4">
                    <Col md={10}>
                        <Form.Group controlId="formLabRequestBaseImage" style={{ width: "85%" }}>
                            <Form.Label>Selezionare la modalità di allocazione postazioni</Form.Label>

                            <Select
                                value={selected}
                                options={allocationMode}
                                isSearchable={false}
                                placeholder="Modalità allocazione"
                                onChange={(e) => {
                                    onSelectChange(e);
                                }}
                            />
                            <br />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {showLibSelection()}
                </Row>

            </Card>

            <br />
        </React.Fragment>


    );

    return ret;
}

export default SelectAllocationMode;
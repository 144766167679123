import React from 'react';
import RegisterLabPage from "./RegisterLabPage";


function RegisterLab(props) {
    var myHome;

    if (!props.apiAuthGroups) {
        myHome = null;
    } else {
        myHome = (
            <RegisterLabPage
                title={props.title}
                handle={props.match.params.handle}
                apiAuthResponse={props.apiAuthResponse}
                apiAuthName={props.apiAuthName}
                apiAuthSurname={props.apiAuthSurname}
                apiAuthGroups={props.apiAuthGroups}
            />

        )

    }

    return myHome;
}

export default RegisterLab;
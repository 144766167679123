import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ClipLoader from "react-spinners/ClipLoader";
import { Link45deg } from "react-bootstrap-icons";
import SwitchPubLab from "./SwitchPubLab";
import PubLabDettPage from "./PubLabDettPage";
import LabConsolleScheduleDettPage from "../labConsolle/LabConsolleScheduleDettPage";

import '../../css/table-sort.css';

const labColumns = [
    {
        dataField: "email",
        text: "email",
        hidden: true,
        sort: false
    },
    {
        dataField: "idLab",
        text: "Identificativo",
        sort: true
    },
    {
        dataField: "labName",
        text: "Nome Laboratorio",
        sort: true
    },
    {
        dataField: "createdAt",
        text: "Data richiesta",
        sort: true
    },
    {
        dataField: "status",
        text: "Stato richiesta"
    },
    {
        dataField: "linkVdi",
        text: "Accesso Laboratorio"
    },
    {
        dataField: "registerUrlStatus",
        text: "Pubblica Laboratorio",
    },
    {
        dataField: "registerUrl",
        text: "URL per la registrazione",
    },
    {
        dataField: "labDetails",
        text: "Dettaglio Laboratorio"
    }

];

const labColumnsAdmin = [
    {
        dataField: "email",
        text: "email",
        hidden: false,
        sort: true
    },
    {
        dataField: "idLab",
        text: "Identificativo",
        sort: true
    },
    {
        dataField: "labName",
        text: "Nome Laboratorio",
        sort: true
    },
    {
        dataField: "createdAt",
        text: "Data richiesta",
        sort: true
    },
    {
        dataField: "status",
        text: "Stato richiesta"
    },
    {
        dataField: "linkVdi",
        text: "Accesso Laboratorio"
    },
    {
        dataField: "registerUrlStatus",
        text: "Pubblica Laboratorio",
    },
    {
        dataField: "registerUrl",
        text: "URL per la registrazione",
    },
    {
        dataField: "labDetails",
        text: "Dettaglio Laboratorio"
    },
    {
        dataField: "labSchedule",
        text: "Pianificazione"
    },


];


function PubLabPage(props) {
    const [labData, setLabData] = useState();
    const [selectedLabProps, setSelectedLabProps] = useState();
    const [isShowSelectedLab, setIsShowSelectedLab] = useState(false);
    const [isShowScheduleLab, setIsShowScheduleLab] = useState(false);
    const [searchEmail, setSearchEmail] = useState("");
    const [isLoading, setIsLoading] = useState(1);

    useEffect(() => {
        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
        };

        setTimeout(async () => {
            setIsLoading(1);
            var myUrl = (props.isAdmin ?
                process.env.REACT_APP_URL_GET_ALL_LAB + "?email=" + searchEmail
                :
                process.env.REACT_APP_URL_GET_PERSONAL_LAB);

            await fetch(myUrl, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    var linkVdi = "";
                    data.forEach(data => {
                        var progressCount = 0;
                        var progressVariant = "";
                        var progressLabel = "";
                        linkVdi = "";
                        switch (data.status) {
                            case "creatingMaster":
                                progressCount = 20;
                                progressLabel = progressCount + '%'
                                break;
                            case "readyMaster":
                                progressCount = 60;
                                progressLabel = progressCount + '%'
                                break;
                            case "creatingLab":
                                progressCount = 80;
                                progressLabel = progressCount + '%'
                                break;
                            case "updatingLab":
                                progressCount = 85;
                                progressLabel = progressCount + '%'
                                break;
                            case "refreshingLab":
                                progressCount = 95;
                                progressLabel = progressCount + '%'
                                break;
                            case "complete":
                                progressCount = 100;
                                progressLabel = "Complete"

                                const popovervdi = (
                                    <Popover id="popovervdi-basic">
                                        <Popover.Title as="h3">Link per accedere alle VM</Popover.Title>
                                        <Popover.Content>
                                            Indirizzo per accedere alle VM del laboratorio.
                                            Per accedere alle VM: <br />
                                            a) utilizzare un browser HTML5 (es. Chrome, Edge), inserendo questo URL nella barra degli indirizzi <br />
                                            b) utilizzare il client VMWare Horizon, specificando questo URL come "Connection Server". <br />
                                            Indirizzo:<br />
                                            <strong>{data.baseImage.destination.url}</strong>
                                            {"     "}
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href=
                                                {data.baseImage.destination.url}
                                            ><Link45deg /></a>
                                            <br />
                                        </Popover.Content>
                                    </Popover>
                                );

                                linkVdi = (
                                    <OverlayTrigger trigger="click" placement="top" overlay={popovervdi}>
                                        <Button variant="primary" size="sm">Link accesso VM</Button>
                                    </OverlayTrigger>
                                );

                                break;
                            case "error":
                                progressCount = 100;
                                progressVariant = "danger";
                                progressLabel = "Error"
                                break;
                            case "dropped":
                                progressCount = 100;
                                progressVariant = "info";
                                progressLabel = "Dropped"
                                break;
                            case "deleted":
                                progressCount = 100;
                                progressVariant = "info";
                                progressLabel = "Deleted"
                                break;
                            case "deleting":
                                progressCount = 100;
                                progressVariant = "info";
                                progressLabel = "Deleting"
                                break;
                            default:

                        }

                        data.statusValue = data.status;
                        data.status = (
                            <ProgressBar
                                variant={progressVariant}
                                now={progressCount}
                                label={progressLabel}
                            />
                        )

                        data.linkVdi = linkVdi;

                        data.createdAt = convertDate(data.createdAt);

                        data.registerUrlStatus = <SwitchPubLab
                            idLab={data.idLab}
                            registerUrlStatus={data.registerUrlStatus}
                        />;

                        if (data.registerUrls) {
                            data.registerUrls.forEach(elem => {
                                if (elem.teacher.email === props.apiAuthResponse) {

                                    const popover = (
                                        <Popover id="popover-basic">
                                            <Popover.Title as="h3">Link per le iscrizioni</Popover.Title>
                                            <Popover.Content>
                                                Indirizzo da fornire agli studenti per effettaure la registrazione al Laboratorio Virtuale:<br />
                                                <strong>{process.env.REACT_APP_URL_REGISTER_LAB_FRONTEND + "/" + elem.registerUrl}</strong>
                                                <br />
                                                Per il funzionamento dell'indirizzo le iscrizioni devono essere abilitate.
                                            </Popover.Content>
                                        </Popover>
                                    );

                                    data.registerUrl = (
                                        <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                                            <Button variant="primary" size="sm">Link iscrizioni</Button>
                                        </OverlayTrigger>
                                    );
                                }
                            })
                        } else {
                            data.registerUrl = "";
                        }

                        data.labDetails = (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => { handleClickDetail(data) }}
                            >
                                Dettaglio
                            </Button>
                        );

                        data.labSchedule = (
                            <Button
                                size="sm"
                                variant="primary"
                                onClick={() => { handleClickSchedule(data) }}
                            >
                                Pianificazione
                            </Button>
                        );                        

                    });

                    setLabData(data);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
            setIsLoading(0);
        });
    }, [props, searchEmail, isShowSelectedLab, isShowScheduleLab]);


    const handleClickDetail = (e) => {
        setSelectedLabProps(e);
        setIsShowSelectedLab(true);
    }

    const handleClickSchedule = (e) => {
        setSelectedLabProps(e);
        setIsShowScheduleLab(true);
    }    

    const searchEmailHandler = (email) => {
        setSearchEmail(email.target.value);
    }

    const searchForm = () => {
        var ret;

        if (props.isAdmin) {
            ret = (
                <Form>
                    <Form.Group controlId="emailSelector" style={{ width: "85%" }}>
                        <Form.Label>Ricerca laboratorio</Form.Label>
                        <Form.Row>
                            <Col>
                                <Form.Text className="text-muted">E-Mail docente</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchEmailHandler}
                                    placeholder="Inserire l'E-Mail del titolare" />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>

            );
        }

        return ret;
    }

    const isLoadingPage = () => {
        var retLoadingPage = null;

        if (isLoading) {
            retLoadingPage = (
                <React.Fragment>
                    <ClipLoader
                        size={300}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </React.Fragment>
            )
        }

        return retLoadingPage;
    }


    const resultTable = () => {
        var ret = null;

        if (!isLoading) {
            ret = (
                <BootstrapTable
                    bootstrap4
                    keyField="idLab"
                    data={labData}
                    columns={props.isAdmin ? labColumnsAdmin : labColumns}
                    striped
                    hover
                    condensed
                    bordered={false}
                />
            );
        }

        return ret;
    }

    const pubLabPage = () => {
        var retPub = null;

        if (!isShowSelectedLab && !isShowScheduleLab) {
            retPub = (
                <React.Fragment>
                    <h2 className="title-page">Pubblicazione Laboratori Virtuali</h2>
                    <p>Pagina di amministrazione dei Laboratori Virtuali</p>

                    {searchForm()}
                    {isLoadingPage()}
                    {resultTable()}

                </React.Fragment>
            );
        }

        return retPub;
    }

    const pubLabDettPage = () => {
        var retDett = null;

        if (isShowSelectedLab) {
            retDett = (
                <PubLabDettPage
                    labProps={selectedLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowSelectedLab={setIsShowSelectedLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }


    const pubLabSchedulePage = () => {
        var retDett = null;

        var myLabProps = null;

        if (selectedLabProps) {
            myLabProps = {
                email: selectedLabProps.email,
                idLab: selectedLabProps.idLab,
                imageOs: selectedLabProps.baseImage.os,
                imageName: selectedLabProps.baseImage.name,
                imageSize: selectedLabProps.baseImage.size,
                imageSizeCustomizable: 0,
                imageFeed: selectedLabProps.baseImage.feed,
                imageFeedBundle: selectedLabProps.baseImage.feedBundle,
                imageDes: selectedLabProps.baseImage.des,
                imageType: selectedLabProps.baseImage.imageType,
                destination: {
                    id: selectedLabProps.baseImage.destId,
                    des: selectedLabProps.baseImage.destDes,
                    url: selectedLabProps.baseImage.destUrl,
                },
                usageData: JSON.stringify(selectedLabProps.usageData),
                teachers: JSON.stringify(selectedLabProps.teachers),
            }
        }


        if (isShowScheduleLab) {
            retDett = (
                <LabConsolleScheduleDettPage
                    labProps={myLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowScheduleLab={setIsShowScheduleLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }


    var ret = (
        <React.Fragment>
            {pubLabDettPage()}
            {pubLabSchedulePage()}
            {pubLabPage()}
        </React.Fragment>
    );

    if (labData) {
        return ret;
    } else {
        return null;
    }


}

export default PubLabPage;

import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';



const styles = {
    card: {
        backgroundColor: '#f2f2f2',
        borderRadius: 10,
        padding: '3rem',
        height: '100%'
    },
    cardImage: {
        objectFit: 'cover',
        borderRadius: 15,
        width: '25%',
    }
}


function SelectBaseDc(props) {
    var ret;

    const onClickDcUnimib = () => {
        props.handleSelectDc('unimib')
    }

    const onClickDcCloud = () => {
        props.handleSelectDc('cloud')
    }


    ret = (
        <Container>
            <Form>
                <Form.Group controlId="formLabRequestBaseImageDc" style={{ width: "100%" }}>
                    <Form.Label>Wizard per la scelta dell'immagine base</Form.Label>

                    <Card style={{ padding: '3rem' }}>


                        <Row xs={1} md={2} className="g-4">

                            <Col md={5}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabCloud.svg" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Cloud</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio verrà collocato su risorse in cloud.
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="primary" onClick={onClickDcCloud}>Seleziona</Button>
                                </Card>
                            </Col>
                            <Col md={5}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabUnimib.png" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Bicocca</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio verrà collocato su infrastrutture Bicocca.
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="primary" onClick={onClickDcUnimib}>Seleziona</Button>
                                </Card>
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <div class="shadow-lg p-1 mb-5 bg-white rounded w-100" >1. Datacenter</div>
                                </Row>
                                <Row>
                                    <div class="shadow-none p-1 mb-5 bg-light rounded w-100">2. Sistema operativo</div>
                                </Row>
                                <Row>
                                    <div class="shadow-none p-1 mb-5 bg-light rounded w-100">3. Tipo immagine</div>
                                </Row>
                                <Row>
                                    <div class="shadow-none p-1 mb-5 bg-light rounded w-100">4. Selezione immagine</div>
                                </Row>
                                <Row>
                                    <Button md={3} variant="secondary w-100" disabled="true">Indietro</Button>
                                </Row>
                            </Col>

                        </Row>

                    </Card>

                    <br />
                </Form.Group>

            </Form>
        </Container>

    );

    return ret;
}

export default SelectBaseDc;
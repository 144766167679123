import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Can from "../components/Can";

import Home from './Home';
import Stanza from './stanzaWebex/Stanza';
import Pillole from './pillole/Pillole';
import ReqLab from './reqLab/ReqLab';
import ReqExamLab from './reqExamLab/ReqExamLab';
import ScheduleLab from './scheduleLab/ScheduleLab';
import LabInventory from './labInventory/LabInventory';
import PubLab from './pubLab/PubLab';
import RegisterLab from './pubLab/RegisterLab';
import PubLabDettPage from './pubLab/PubLabDettPage';
import LabConsolle from './labConsolle/LabConsolle';
import LabConsolleSchedule from './labConsolle/LabConsolleSchedule';


function BootstrapNavbar(props) {
    var loginButton;
    var areaStudente;
    var areaGuide;
    var areaDocente;
    var areaTim;
    var itemVdiGuide;
    var itemPillole;
    var itemJira;
    var itemStanza;
    var itemLabRequest;
    var itemLabSchedule;
    var itemLabPub;
    var itemLabExamRequest;
    var itemLabInventory;
    var areaAmministrazioneLab;
    var itemLabConsolle;
    var itemLabConsolleSchedule;


    if (props.apiAuthResponse) {
        loginButton = <Nav.Link href={process.env.REACT_APP_URL_AUTH_LOGOUT}>Logout</Nav.Link>;
    } else {
        loginButton = <Nav.Link href={process.env.REACT_APP_URL_AUTH_LOGIN}>Login</Nav.Link>;
    }

    if (props.apiAuthGroups) {

        areaGuide = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown title="Guide" id="basic-nav-dropdown">
                    {itemVdiGuide}
                    {itemPillole}
                    {itemJira}
                    {itemStanza}
                </NavDropdown>
            )}
            no={() => null}
        />

        areaStudente = <Can
            role={props.apiAuthGroups}
            perform="studentArea:visible"
            yes={() => (
                <NavDropdown title="Area studente" id="basic-nav-dropdown">
                </NavDropdown>
            )}
            no={() => null}
        />

        areaDocente = <Can
            role={props.apiAuthGroups}
            perform="teacherArea:visible"
            yes={() => (
                <NavDropdown title="Area docente" id="basic-nav-dropdown">
                    {itemLabRequest}
                    {itemLabSchedule}
                    {itemLabPub}
                </NavDropdown>
            )}
            no={() => null}
        />

        areaAmministrazioneLab = <Can
            role={props.apiAuthGroups}
            perform="adminLabArea:visible"
            yes={() => (
                <NavDropdown title="Amministrazione" id="basic-nav-dropdown">
                    {itemLabConsolle}
                    {itemLabConsolleSchedule}
                    {itemLabInventory}
                    {itemLabExamRequest}
                </NavDropdown>
            )}
            no={() => null}
        />

        itemLabConsolle = <Can
            role={props.apiAuthGroups}
            perform="labConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/labConsolle">Consolle Laboratori</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabConsolleSchedule = <Can
            role={props.apiAuthGroups}
            perform="labConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/labConsolleSchedule">Vista pianificazione Laboratori</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemVdiGuide = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item
                    target="_blank"
                    href={process.env.REACT_APP_URL_VDI_DOC}>
                    Documentazione LIBaaS
                </NavDropdown.Item>
            )}
            no={() => null}
        />

        itemPillole = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/Pillole">Pillole video LIBaaS</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemJira = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item
                    target="_blank"
                    href={process.env.REACT_APP_URL_SERVICEDESK}>
                    Apri una richiesta di assistenza
                </NavDropdown.Item>
            )}
            no={() => null}
        />

        itemStanza = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/Stanza">Stanza di helpdesk (137 820 4977)</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabRequest = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ReqLab">Richiesta nuovo laboratorio</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabSchedule = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ScheduleLab">Pianificazione attività in un laboratorio esistente</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabPub = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/PubLab">Pubblicazione laboratori</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabExamRequest = <Can
            role={props.apiAuthGroups}
            perform="labExamRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ReqExamLab">Richiesta nuovo laboratorio esame</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabInventory = <Can
            role={props.apiAuthGroups}
            perform="labExamRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/LabInventory">Inventario LIB (laboratori fisici)</NavDropdown.Item>
            )}
            no={() => null}
        />

    }

    return (
        <React.Fragment>
            <Router>
                <Container>

                    <Navbar bg="light" expand="md">
                        <Navbar.Brand as={Link} to="/">LIBaaS</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link as={Link} to="/">Home</Nav.Link>
                                {areaStudente}
                                {areaDocente}
                                {areaTim}
                                {areaAmministrazioneLab}
                                {areaGuide}
                            </Nav>
                            {loginButton}

                        </Navbar.Collapse>
                    </Navbar>

                    <Switch>
                        <Route path="/" exact>
                            <Home
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />
                        </Route>
                        <Route path="/Pillole" exact>
                            <Pillole
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/Stanza" exact>
                            <Stanza
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/ReqLab" exact>
                            <ReqLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/ScheduleLab" exact>
                            <ScheduleLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/ReqExamLab" exact>
                            <ReqExamLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/LabInventory" exact>
                            <LabInventory
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/PubLab" exact>
                            <PubLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/PubLab/register/:handle"
                            render={(newProps) => <RegisterLab {...newProps}
                                title={`Pagina di registrazione ai Laboratori Virtuali`}
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />} >
                        </Route>
                        <Route path="/labConsolle" exact>
                            <LabConsolle
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/labConsolleSchedule" exact>
                            <LabConsolleSchedule
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        </Route>
                        <Route path="/PubLabDettPage/" exact component={PubLabDettPage} />
                        <Route render={function () {
                            return <p>Pagina non trovata</p>
                        }} />
                    </Switch>

                </Container>
            </Router>
        </React.Fragment>


    );
}




export default BootstrapNavbar;
import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from 'react-bootstrap/Button';

function LabServicesSelect(props) {
    const firstRender = useRef(true);

    const [isLoading, setIsLoading] = useState(0);
    const [accessToken, setAccesstoken] = useState("");
    const [subscriptionId, setSubscriptionId] = useState("");
    const [resourceGroupName, setResourceGroupName] = useState("");
    const [labName, setLabName] = useState("");
    const [payload, setPayLoad] = useState({})
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [labServicesVirtualmachines, setLabServicesVirtualmachines] = useState({});
    //const [isSubmitted, setIsSubmitted] = useState(0);


    var ret = null;

    const subscriptionIdHandler = (event) => {
        setSubscriptionId(event.target.value);
    }

    const resourceGroupNameHandler = (event) => {
        setResourceGroupName(event.target.value);
    }

    const labNameHandler = (event) => {
        setLabName(event.target.value);
    }

    useEffect(() => {
        const formValidation = () => {
            var isOkLabName = false;
            var isOkSubscriptionId = false;
            var isOkResourceGroupName = false;

            if (subscriptionId === "" || !subscriptionId.match(/^[a-z|A-Z|0-9|-]*$/)) {
                isOkSubscriptionId = false
            } else {
                isOkSubscriptionId = true
            }

            if (resourceGroupName === "" || !resourceGroupName.match(/^[a-z|A-Z|0-9|-|_]*$/)) {
                isOkResourceGroupName = false
            } else {
                isOkResourceGroupName = true
            }

            if (labName === "" || !labName.match(/^[a-z|A-Z|0-9|-|_]*$/)) {
                isOkLabName = false
            } else {
                isOkLabName = true
            }



            return (isOkLabName && isOkSubscriptionId && isOkResourceGroupName);
        }

        setIsSubmitble(formValidation());

    }, [subscriptionId, resourceGroupName, labName]);



    useEffect(() => {
        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        var formBody = [];
        var url = process.env.REACT_APP_URL_LABSERVICES_VM;

        formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

        setTimeout(async () => {
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    const myVm = [];
                    data.forEach(element => {
                        if(element.element.properties.vmType !== 'Template') {
                        myVm.push(element);
                        }
                    });
                    return myVm;
                })
                .then((data) => {
                    //console.log(JSON.stringify(data));
                    setLabServicesVirtualmachines(data);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });

        setIsLoading(0);

    }, [accessToken, payload]);

    useEffect(() => {
        props.setLabServicesVirtualmachines(labServicesVirtualmachines);
    }, [props, labServicesVirtualmachines])


    const onSubmit = (e) => {
        e.preventDefault();

        setTimeout(async () => {
            setIsLoading(1);
            await fetch(process.env.REACT_APP_URL_LABSERVICES_OAUTH2,
                {
                    credentials: 'include',
                    method: "GET"
                })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    props.setLabType("examLabServices")
                    const myPayload = (
                        {
                            access_token: data.access_token,
                            subscriptionId: subscriptionId,
                            resourceGroupName: resourceGroupName,
                            labName: labName
                        }
                    );
                    setPayLoad(myPayload);
                    setAccesstoken(data.access_token);

                })

                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });


    }



    ret = (
        <React.Fragment>
            <Container>
                <Form onSubmit={onSubmit}>

                    <Form.Group controlId="formSubscriptionId" style={{ width: "85%" }}>
                        <Form.Label>Subscription ID</Form.Label>
                        <Form.Text className="text-muted">
                            <b>Dato obbligatorio</b>
                        </Form.Text>
                        <Form.Control style={{ width: "100%" }}
                            required
                            type="text"
                            onChange={subscriptionIdHandler}
                            placeholder="Inserire l'identificativo della sottoscrizione ove è ubicato il laboratorio virtuale" />
                    </Form.Group>
                    <Form.Group controlId="formrg" style={{ width: "85%" }}>
                        <Form.Label>Resource Group Name</Form.Label>
                        <Form.Text className="text-muted">
                            <b>Dato obbligatorio</b>
                        </Form.Text>
                        <Form.Control style={{ width: "100%" }}
                            required
                            type="text"
                            onChange={resourceGroupNameHandler}
                            placeholder="Inserire il gruppo di risorse" />
                    </Form.Group>
                    <Form.Group controlId="formrg" style={{ width: "85%" }}>
                        <Form.Label>Lab Name</Form.Label>
                        <Form.Text className="text-muted">
                            <b>Dato obbligatorio</b>
                        </Form.Text>
                        <Form.Control style={{ width: "100%" }}
                            required
                            type="text"
                            onChange={labNameHandler}
                            placeholder="Inserire il nome del Lab Service" />
                    </Form.Group>


                    <br />
                    <br />

                    <div>
                        <Button variant="primary" type="submit" disabled={!isSubmitble}>
                            Associa Laboratorio
                        </Button>
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>

                    <br />
                </Form>
            </Container>
        </React.Fragment >
    );


    return ret;
}

export default LabServicesSelect;
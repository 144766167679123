import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button"
import BootstrapTable from "react-bootstrap-table-next";
import ClipLoader from 'react-spinners/ClipLoader';
import UpdateRegisteredStudents from './UpdateRegisteredStudents';
import SelectRepoPkg from '../reqLab/SelectRepoPkg';
import ReqLabConfirm from '../reqLab/ReqLabConfirm';
import SelectAllocationMode from '../reqExamLab/SelectAllocationMode';
import Alert from 'react-bootstrap/Alert';


import '../../css/table-sort.css';

const teacherColumns = [
    {
        dataField: "teacher",
        text: "Docente",
        sort: true
    },
];

const studentColumns = [
    {
        dataField: "teacher",
        text: "Docente",
        sort: true
    },
    {
        dataField: "partition",
        text: "Turno",
        sort: true
    },
    {
        dataField: "email",
        text: "Studente",
        sort: true
    },
    {
        dataField: "registrationDate",
        text: "Data iscrizione",
        sort: true
    },
];

function PubLabDettPage(props) {
    const [students, setStudents] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [installedPackages, setInstalledPackages] = useState([]);
    const [isPkgListModified, setIsPkgListModified] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [statusRequestUpdRegisteredStudents, setStatusRequestUpdRegisteredStudents] = useState();
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isSubmittedUpdRegisteredStudents, setIsSubmittedUpdRegisteredStudents] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [examStudents, setExamStudents] = useState([]);
    const [examIsSubmitble, setExamIsSubmitble] = useState(false);

    var ret = null;

    useEffect(() => {
        const tabStudent = [];
        const tabTeacher = [];

        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            if (inputFormat) {
                var d = new Date(inputFormat)
                return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/') +
                    ' ' + [pad(d.getHours()), pad(d.getMinutes())].join(':')
            } else {
                return 'N/A'
            }
        };

        if (props.labProps) {
            if (props.labProps.students) {
                props.labProps.students.map((value) => {
                    if (value.teacher.email === props.apiAuthResponse || props.isAdmin) {

                        tabStudent.push(
                            {
                                teacher: value.teacher.email,
                                partition: value.teacher.partition,
                                email: value.email,
                                registrationDate: convertDate(value.registrationDate),
                            }
                        )
                    }
                    return tabStudent;
                });
                setStudents(tabStudent);
            }

            if (props.labProps.teachers) {
                props.labProps.teachers.map((value) => {
                    tabTeacher.push(
                        {
                            teacher: value.email,
                        }
                    )
                    return tabTeacher;
                });
                setTeachers(tabTeacher);
            }

        }

        setRepoPkgBundleSel(props.labProps.repoPkgBundleSel);
        setInstalledPackages(props.labProps.repoPkgSel);

        if(examStudents.length > 0) {
            setExamIsSubmitble(true);
        }

    }, [props.labProps, props.apiAuthResponse, props.isAdmin, examStudents]);




    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var tempArraySelectedPackages = [];
        var url;

        url = process.env.REACT_APP_URL_UPD_REQ_LAB;

        tempArraySelectedPackages = installedPackages.concat(repoPkgSel);

        const payload = (
            {
                idLab: props.labProps.idLab,
                email: props.apiAuthResponse,
                teachers: props.labProps.teachers,
                labName: props.labProps.labName,
                labDescription: props.labProps.labDescription,
                repoPkgBundleSel: repoPkgBundleSel,
                repoPkgSel: tempArraySelectedPackages,
                adSel: props.labProps.adSel,
                labOtherActivities: props.labProps.labOtherActivities,
                labNotes: props.labProps.labNotes,
                baseImage: {
                    os: props.labProps.baseImage.os,
                    name: props.labProps.baseImage.name,
                    des: props.labProps.baseImage.des,
                    size: props.labProps.baseImage.size,
                    feed: props.labProps.baseImage.feed,
                    feedBundle: props.labProps.baseImage.feedBundle,
                    imageType: props.labProps.baseImage.imageType,
                    destination: props.labProps.baseImage.destination,
                },
                usageData: {
                    maxStudentsPerPartition: props.labProps.usageData.maxStudentsPerPartition,
                    //quota: props.labProps.usageDataquota,
                    //schedulerLab: labEvents,
                },
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log(JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const onSubmitUpdateRegisteredStudents = (e) => {
        e.preventDefault();
        var formBody = [];
        var tempArrayRegisteredStudents = [];
        const updTabStudents = [];
        var url;

        url = process.env.REACT_APP_URL_UPD_REG_STD;

        // array contenente i soli studenti iscritti
        tempArrayRegisteredStudents = props.labProps.students.filter(n => !selectedStudents.includes(n.email));

        tempArrayRegisteredStudents.forEach(elem => {
            var student = {
                teacher: elem.teacher.email,
                partition: elem.teacher.partition,
                email: elem.email,
                registrationDate: elem.registrationDate
            }

            updTabStudents.push(student);
        })

        const payload = (
            {
                idLab: props.labProps.idLab,
                students: tempArrayRegisteredStudents,
                status: 'refreshingLab'
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log(JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmittedUpdRegisteredStudents(1);
                    setIsLoading(0);
                    setStatusRequestUpdRegisteredStudents(data.statusRequestUpdRegisteredStudents);

                    setStudents(updTabStudents)

                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const onSubmitUpdateExamStudents = (e) => {
        e.preventDefault();
        var formBody = [];
        const updTabStudents = [];
        var url;

        url = process.env.REACT_APP_URL_UPD_REG_STD;

        const payload = (
            {
                idLab: props.labProps.idLab,
                students: examStudents,
                status: 'refreshingLab'
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log(JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmittedUpdRegisteredStudents(1);
                    setIsLoading(0);
                    setStatusRequestUpdRegisteredStudents(data.statusRequestUpdRegisteredStudents);

                    setStudents(updTabStudents)

                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }

    const editAllocationMode = (status, labType) => {
        const buttonUpdateExamStudents = (
            <React.Fragment>
                <UpdateRegisteredStudents
                    isSubmittedUpdRegisteredStudents={isSubmittedUpdRegisteredStudents}
                    isSubmitble={examIsSubmitble}
                    statusRequestUpdRegisteredStudents={statusRequestUpdRegisteredStudents}
                    apiAuthGroups={props.apiAuthGroups}
                    buttonDescription={"Aggiorna postazioni esami"}
                />

                <br />
                <ClipLoader
                    size={35}
                    color={"#a71e3b"}
                    loading={isLoading}
                />
            </React.Fragment>
        )

        if (status === 'complete' && labType === 'exam') {
            var ret = (
                <Form.Group style={{ width: "100%" }}>
                    <SelectAllocationMode
                        apiAuthResponse={props.apiAuthResponse}
                        setStudents={setExamStudents}
                        students={examStudents}
                    />
                    {buttonUpdateExamStudents}
                </Form.Group>
            );

            return ret;
        }
    }

    const studentList = (status) => {
        var retStudent = null;
        var labelStudentBooked = (props.isAdmin ? "Studenti iscritti" : "Propri studenti iscritti")

        const handleOnSelect = (row, isSelect) => {
            if (isSelect) {
                setSelectedStudents([...selectedStudents, row.email])
            } else {
                setSelectedStudents(selectedStudents.filter(x => x !== row.email))
            }

            return true;
        }

        const handleOnSelectAll = (isSelect, rows) => {
            if (isSelect) {
                setSelectedStudents(rows.map(r => r.email));
            } else {
                setSelectedStudents([])
            }

            return true;
        }

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            clickToEdit: true,
            onSelect: handleOnSelect,
            onSelectAll: handleOnSelectAll
        };

        const buttonRemoveSelStudents = (
            <React.Fragment>
                <Alert variant="warning">
                    La rimozione di studenti iscritti ad un lab virtuale causa il reset di tutte le macchine virtuali, anche degli studenti non rimossi e dei docenti. <br />
                    WARNING: se fatto durante una lezione causa l'interruzione della lezione.
                </Alert>
                <UpdateRegisteredStudents
                    isSubmittedUpdRegisteredStudents={isSubmittedUpdRegisteredStudents}
                    isSubmitble={true}
                    statusRequestUpdRegisteredStudents={statusRequestUpdRegisteredStudents}
                    apiAuthGroups={props.apiAuthGroups}
                    buttonDescription={"Rimuovi studenti selezionati"}
                />

                <br />
                <ClipLoader
                    size={35}
                    color={"#a71e3b"}
                    loading={isLoading}
                />
            </React.Fragment>
        )


        if (status === 'complete') {
            retStudent = (

                <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                    <Form.Label>Docenti gestori del laboratorio</Form.Label>
                    <BootstrapTable
                        bootstrap4
                        keyField="email"
                        data={teachers}
                        columns={teacherColumns}
                        striped
                        hover
                        condensed
                        bordered={false}
                    />
                    <br />
                    <Form.Label>{labelStudentBooked}</Form.Label>
                    <BootstrapTable
                        bootstrap4
                        keyField="email"
                        data={students}
                        columns={studentColumns}
                        striped
                        hover
                        condensed
                        bordered={false}
                        selectRow={selectRow}
                    />
                    {buttonRemoveSelStudents}
                </Form.Group>
            );
        }

        return retStudent;
    }


    const repoPkgSelector = (status) => {
        var retPkgSelector = null;
        var buttonSubmit = null;

        if ((status === 'complete' || status === 'error') &&
            props.labProps.baseImage.imageType === 'template'
        ) {

            var msgPkgListModified = "";
            if (isPkgListModified) {
                msgPkgListModified = (
                    <p>Lista pacchetti modificata</p>
                )
            }

            buttonSubmit = (
                <React.Fragment>
                    <ReqLabConfirm
                        isSubmitted={isSubmitted}
                        isSubmitble={true}
                        statusRequest={statusRequest}
                        apiAuthGroups={props.apiAuthGroups}
                        buttonDescription={"Ripubblica laboratorio"}
                    />

                    <br />
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </React.Fragment>
            )




            retPkgSelector = (
                <React.Fragment>
                    <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                        <SelectRepoPkg
                            apiAuthResponse={props.apiAuthResponse}
                            apiAuthName={props.apiAuthName}
                            apiAuthSurname={props.apiAuthSurname}
                            apiAuthGroups={props.apiAuthGroups}
                            setRepoPkgSel={setRepoPkgSel}
                            setRepoPkgBundleSel={setRepoPkgBundleSel}
                            installedPackages={installedPackages}
                            baseImage={props.labProps.baseImage}
                            setIsPkgListModified={setIsPkgListModified}
                        />
                        {msgPkgListModified}
                        {buttonSubmit}
                    </Form.Group>
                </React.Fragment>
            );
        }

        return retPkgSelector;
    }

    ret = (
        <React.Fragment>
            <h2 className="title-page">Dettaglio Laboratorio Virtuale {props.labProps.labName}</h2>

            <Button
                size="sm"
                variant="primary"
                onClick={() => { props.setIsShowSelectedLab(false) }}
            >
                Torna alla vista complessiva dei laboratori
            </Button>
            <br />
            <hr />

            <Form onSubmit={onSubmit}>
                <Form.Group style={{ width: "100%" }}>
                    <Form.Label>E-mail richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.email}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Identificativo Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.idLab}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Tipologia Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.labType}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Data Richiesta Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.createdAt}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Stato Laboratorio</Form.Label>
                    {props.labProps.status}
                    <br />

                    <Form.Label>Sistema Operativo</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.baseImage.os}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Immagine base</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.baseImage.name}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Numero studenti iscritti</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.students.length}`}
                        readOnly
                    />
                    <br />
                </Form.Group>

                {repoPkgSelector(props.labProps.statusValue)}

            </Form>

            <Form onSubmit={onSubmitUpdateRegisteredStudents}>
                {studentList(props.labProps.statusValue)}
            </Form>

            <Form onSubmit={onSubmitUpdateExamStudents}>
                {editAllocationMode(props.labProps.statusValue, props.labProps.labType)}
            </Form>

            <hr />
            <Button
                size="sm"
                variant="primary"
                onClick={() => { props.setIsShowSelectedLab(false) }}
            >
                Torna a Pubblicazione Laboratori
            </Button>
        </React.Fragment>
    );


    if (props.labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="primary"
                onClick={() => { props.setIsShowSelectedLab(false) }}
            >
                Torna a Pubblicazione Laboratori
            </Button>
        );
    }

}

export default PubLabDettPage;

import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';



const styles = {
    card: {
        backgroundColor: '#f2f2f2',
        borderRadius: 10,
        padding: '3rem',
        height: '100%'
    },
    cardImage: {
        objectFit: 'cover',
        borderRadius: 15,
        width: '25%',
    }
}


function SelectBaseDc(props) {
    var ret;

    const onClickDcExamUnimib = () => {
        props.handleSelectDc('examunimib')
    }

    const onClickDcUnimib = () => {
        props.handleSelectDc('unimib')
    }

    const onClickDcCloud = () => {
        props.handleSelectDc('cloud')
    }


    ret = (
        <Container>
            <Form>
                <Form.Group controlId="formLabRequestBaseImageDc" style={{ width: "100%" }}>
                    <Form.Label>Wizard per la scelta dell'immagine base</Form.Label>

                    <Card style={{ padding: '3rem' }}>

                        <Row xs={1} md={4} className="g-4">
                            <Col md={4}>
                                <p>Selezione datacenter</p>
                            </Col>
                        </Row>
                        <Row xs={1} md={4} className="g-4">
                            <Col md={4}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabUnimib.png" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Esame Bicocca</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio verrà collocato su infrastrutture
                                            Bicocca per esami.
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="primary" onClick={onClickDcExamUnimib}>Seleziona</Button>
                                </Card>
                            </Col>

                            <Col md={4}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabUnimib.png" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Lezione Bicocca</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio verrà collocato su infrastrutture
                                            Bicocca per lezioni.
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="primary" onClick={onClickDcUnimib}>Seleziona</Button>
                                </Card>
                            </Col>

                            <Col md={4}>
                                <Card
                                    style={styles.card}
                                >
                                    <Card.Img variant="top" src="/reqLabCloud.svg" style={styles.cardImage} />
                                    <Card.Body>
                                        <Card.Title>Cloud (AVS)</Card.Title>
                                        <Card.Text>
                                            Selezionando questa opzione il laboratorio verrà collocato su risorse in cloud (AVS).
                                        </Card.Text>
                                    </Card.Body>
                                    <Button variant="primary" onClick={onClickDcCloud}>Seleziona</Button>
                                </Card>
                            </Col>
                        </Row>

                    </Card>

                    <br />
                </Form.Group>

            </Form>
        </Container>

    );

    return ret;
}

export default SelectBaseDc;
import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Overlay from 'react-bootstrap/Overlay';

function LabName(props) {
    const [localLabName, setLocalLabName] = useState();
    const [showDupAlert, setShowDupAlert] = useState(false);
    const targetDupAlert = useRef(null);
    var ret;

    const labNameHandler = (event) => {
        setLocalLabName(event.target.value);
    }

    useEffect(() => {
        var globalLabName;

        if (localLabName) {
            //console.log("DEBUG localLabName: " + localLabName);
            if (props.adSel) {
                globalLabName = props.adSel.codIns + '-' +
                    props.adSel.codMod + '-' +
                    localLabName;
                /*
                console.log("DEBUG test da labname" + props.adSel.codIns + '_' +
                    props.adSel.codMod + '_' +
                    ' props.labName: ' + props.labName +
                    ' localLabName: ' + localLabName);
                    */
                props.labNameSelected(globalLabName);
            } else {
                if (localLabName.length > 0) {
                    globalLabName = 'other-' + localLabName;
                    props.labNameSelected(globalLabName);
                }
            }

            setTimeout(async () => {
                await fetch(process.env.REACT_APP_URL_CHECKNAME_LAB + "?labName="
                    + globalLabName, {
                    credentials: 'include',
                    method: "GET"
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        if (data.length > 0 && !props.isSubmitted) {
                            setShowDupAlert(true);
                            props.setIsLabnameChecked(false);
                        } else {
                            setShowDupAlert(false);
                            props.setIsLabnameChecked(true);
                        }
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            });

        }

    }, [localLabName, props])


    ret = (
        <React.Fragment >
            <Form.Label>Nome del Laboratorio Virtuale</Form.Label>
            <Form.Text className="text-muted">
                        <b>Dato obbligatorio</b>
            </Form.Text>
            <Form.Control style={{ width: "100%" }}
                required
                ref={targetDupAlert}
                type="text"
                onChange={labNameHandler}
                placeholder="Inserire il nome del Laboratorio" />

            <Overlay transition={false} target={targetDupAlert.current} show={showDupAlert} placement="top">
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            backgroundColor: 'rgba(255, 100, 100, 0.85)',
                            padding: '2px 10px',
                            color: 'white',
                            borderRadius: 3,
                            ...props.style,
                        }}
                    >
                        Nome Laboratorio Virtuale già usato!
                    </div>
                )}
            </Overlay>
            <Overlay transition={false} target={targetDupAlert.current} show={!props.isLabnameSpellChecked && !showDupAlert} placement="top">
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            backgroundColor: 'rgba(255, 100, 100, 0.85)',
                            padding: '2px 10px',
                            color: 'white',
                            borderRadius: 3,
                            ...props.style,
                        }}
                    >
                        Attenzione! Il nome inserito contiene caratteri non consentiti.
                    </div>
                )}
            </Overlay>
        </React.Fragment >


    );

    return ret;


}

export default LabName;
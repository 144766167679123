import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ClipLoader from "react-spinners/ClipLoader";
import { Link45deg } from "react-bootstrap-icons";

import '../../css/table-sort.css';

const labColumns = [
    {
        dataField: "email",
        text: "email",
        hidden: true,
        sort: false
    },
    {
        dataField: "idLab",
        text: "Identificativo Laboratorio",
        sort: true
    },
    {
        dataField: "labName",
        text: "Nome Laboratorio",
        sort: true
    },
    {
        dataField: "linkVdi",
        text: "Accesso Laboratorio"
    }
];

function SubbedLabPage(props) {
    const [labData, setLabData] = useState();
    const [isLoading, setIsLoading] = useState(1)
    const [numLabSubbed, setNumLabSubbed] = useState(0);

    useEffect(() => {
        var auxLab = 0;
        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            var d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
        };

        setTimeout(async () => {
            setIsLoading(1);
            var myUrl = (process.env.REACT_APP_URL_GET_PERSONAL_LAB);

            await fetch(myUrl, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    var linkVdi = "";
                    data.forEach(data => {
                        auxLab++;
                        linkVdi = "";

                        switch (data.status) {
                            case "complete":

                                const popovervdi = (
                                    <Popover id="popovervdi-basic">
                                        <Popover.Title as="h3">Link per accedere alle VM</Popover.Title>
                                        <Popover.Content>
                                            Indirizzo per accedere alle VM del laboratorio.
                                            Per accedere alle VM: <br />
                                            a) utilizzare un browser HTML5 (es. Chrome, Edge), inserendo questo URL nella barra degli indirizzi <br />
                                            b) utilizzare il client VMWare Horizon, specificando questo URL come "Connection Server". <br />
                                            Indirizzo:<br />
                                            <strong>{data.baseImage.destination.url}</strong>
                                            {"     "}
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href=
                                                {data.baseImage.destination.url}
                                            ><Link45deg /></a>
                                            <br />
                                        </Popover.Content>
                                    </Popover>
                                );

                                linkVdi = (
                                    <OverlayTrigger trigger="click" placement="top" overlay={popovervdi}>
                                        <Button variant="primary" size="sm">Link accesso VM</Button>
                                    </OverlayTrigger>
                                );

                                break;
                            default:
                                linkVdi = "Temporaneamente non disponibile"
                        }


                        data.linkVdi = linkVdi;

                        data.createdAt = convertDate(data.createdAt);

                    });

                    setLabData(data);
                    setNumLabSubbed(auxLab);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
            setIsLoading(0);
        });

    }, [props]);



    const isLoadingPage = () => {
        var retLoadingPage = null;

        if (isLoading) {
            retLoadingPage = (
                <React.Fragment>
                    <ClipLoader
                        size={300}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </React.Fragment>
            )
        }

        return retLoadingPage;
    }


    const resultTable = () => {
        var ret = null;

        if (!isLoading && numLabSubbed > 0) {            
            ret = (
                <BootstrapTable
                    bootstrap4
                    keyField="idLab"
                    data={labData}
                    columns={labColumns}
                    striped
                    hover
                    condensed
                    bordered={false}
                />
            );
        }

        return ret;
    }

    const pubLabPage = () => {
        var retPub = null;

        retPub = (
            <React.Fragment>
                <h2 className="title-page">Laboratori Virtuali</h2>
                <p>Il suo account risulta iscritto a {numLabSubbed} Laboratori Virtuali</p>

                {isLoadingPage()}
                {resultTable()}

            </React.Fragment>
        );

        return retPub;
    }


    var ret = (
        <React.Fragment>
            {pubLabPage()}
        </React.Fragment>
    );

    if (labData) {
        return ret;
    } else {
        return null;
    }


}

export default SubbedLabPage;

import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Can from "../Can";
import SelectAd from './SelectAd';
import SelectRepoPkg from './SelectRepoPkg';
import ReqLabConfirm from './ReqLabConfirm';
import MultipleStepBaseImage from './MultipleStepBaseImage';
import SelectSize from './SelectSize';
import LabName from './LabName';
import LabDescription from './LabDescription';
import ClipLoader from 'react-spinners/ClipLoader';
import AgreementLab from './AgreementLab';
import Alert from 'react-bootstrap/Alert';


function ReqLabPage(props) {
    const firstRender = useRef(true);

    const [adSel, setAdSel] = useState();
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [installedPackages] = useState([]);
    const [labName, setLabName] = useState("");
    const [labDescription, setLabDescription] = useState("");
    const [labNotes, setLabNotes] = useState();
    const [labOtherActivities, setLabOtherActivities] = useState("");
    const [baseImage, setBaseImage] = useState({});
    const [isBookable, setIsBookable] = useState(false);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [isPkgListModified, setIsPkgListModified] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [isLabnameChecked, setIsLabnameChecked] = useState(true);
    const [isLabnameSpellChecked, setIsLabnameSpellChecked] = useState(true);
    const [isLabdescriptionSpellChecked, setIsLabdescriptionSpellChecked] = useState(true);
    const [isAdSpellChecked, setIsAdSpellChecked] = useState(true);

    const labType = "standard";

    useEffect(() => {
        const formValidation = () => {
            var isOkLabName = false;
            var isOkLabDescription = false;
            var isOkAttivitaDidattica = false;

            if (!isLabnameChecked || labName === "" || !labName.match(/^[a-z|A-Z|0-9|-]*$/)) {
                isOkLabName = false
            } else {
                isOkLabName = true
                setIsLabnameSpellChecked(true);
            }

            if (labName.length > 0 && isOkLabName === false) {
                setIsLabnameSpellChecked(false);
            } else {
                setIsLabnameSpellChecked(true);
            }

            if (labDescription === "" || !labDescription.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkLabDescription = false
            } else {
                isOkLabDescription = true
                setIsLabdescriptionSpellChecked(true);
            }

            if (labDescription.length > 0 && isOkLabDescription === false) {
                setIsLabdescriptionSpellChecked(false);
            } else {
                setIsLabdescriptionSpellChecked(true);
            }

            if (labOtherActivities === "" || !labOtherActivities.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkAttivitaDidattica = false;
            } else {
                isOkAttivitaDidattica = true;
                setIsAdSpellChecked(true);
            }

            if (labOtherActivities.length > 0 && isOkAttivitaDidattica === false) {
                setIsAdSpellChecked(false);
            } else {
                setIsAdSpellChecked(true);
            }

            if (isOkAttivitaDidattica === false && adSel !== undefined) {
                isOkAttivitaDidattica = true;
            }

            return (isOkLabName && isOkLabDescription && isOkAttivitaDidattica);
        }

        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsSubmitble(formValidation());

    }, [labName, labDescription, isLabnameChecked, labOtherActivities, adSel]);

    const demoMessage = () => {
        var message = (
            <Can
                role={props.apiAuthGroups}
                perform="labRequest-page:confirm"
                yes={() => (null)}
                no={() => (
                    <Alert variant="warning">
                        <Alert.Heading>Funzionalità di richiesta Laboratorio Virtuale non abilitata</Alert.Heading>
                        <p>
                            La funzionalità di richiesta Laboratorio Virtuale è in corso di attivazione e sarà a breve disponibile.
                        </p>
                    </Alert>
                )}
            />
        )

        return message;
    }

    const usageType = () => {
        if (!baseImage.imageName) {
            return (
                <React.Fragment>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Form onSubmit={onSubmit}>

                    <Form.Group controlId="formLabRequestSw" style={{ width: "85%" }}>
                        <SelectRepoPkg
                            apiAuthResponse={props.apiAuthResponse}
                            apiAuthName={props.apiAuthName}
                            apiAuthSurname={props.apiAuthSurname}
                            apiAuthGroups={props.apiAuthGroups}
                            setRepoPkgSel={setRepoPkgSel}
                            setRepoPkgBundleSel={setRepoPkgBundleSel}
                            installedPackages={installedPackages}
                            baseImage={baseImage}
                            setIsPkgListModified={setIsPkgListModified}
                        />
                    </Form.Group>
                    <br />

                    <SelectSize
                        setBaseImage={setBaseImage}
                        baseImage={baseImage}
                    />
                    <br />

                    <Form.Group style={{ width: "100%" }}>
                        <LabName
                            apiAuthResponse={props.apiAuthResponse}
                            apiAuthName={props.apiAuthName}
                            apiAuthSurname={props.apiAuthSurname}
                            apiAuthGroups={props.apiAuthGroups}
                            adSel={adSel}
                            labNameSelected={setLabName}
                            labName={labName}
                            setIsLabnameChecked={setIsLabnameChecked}
                            isLabnameSpellChecked={isLabnameSpellChecked}
                            isSubmitted={isSubmitted}
                        />
                        <br />
                        <LabDescription
                            labDescriptionHandler={labDescriptionHandler}
                            isLabdescriptionSpellChecked={isLabdescriptionSpellChecked}
                        />
                        <br />
                        <Form.Group controlId="formLabRequest" style={{ width: "85%" }}>
                            <SelectAd
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                adSelected={setAdSel}
                                isAdSpellChecked={isAdSpellChecked}
                                setCoperTeacher={setCoperTeacher}
                                labOtherActivitiesSelected={setLabOtherActivities}
                                labName={labName}
                            />
                        </Form.Group>
                        <br />
                        <Form.Label>Note aggiuntive</Form.Label>
                        <Form.Control style={{ width: "85%" }}
                            as="textarea"
                            rows="3"
                            maxLength="2000"
                            placeholder="Inserire eventuali note aggiuntive"
                            onChange={labNotesHandler}
                        />
                    </Form.Group>

                    <br />

                    <AgreementLab />

                    <div>
                        <ReqLabConfirm
                            isSubmitted={isSubmitted}
                            isSubmitble={isSubmitble}
                            statusRequest={statusRequest}
                            apiAuthGroups={props.apiAuthGroups}
                            isPkgListModified={isPkgListModified}
                            buttonDescription={"Istanzia laboratorio"}
                        />
                        <br />
                        <ClipLoader
                            size={35}
                            color={"#a71e3b"}
                            loading={isLoading}
                        />
                    </div>

                    <br />
                </Form>
            </React.Fragment>
        );


    }

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var myTeachers = [];

        var url;


        if (isBookable) {
            url = process.env.REACT_APP_URL_BOOK_LAB;
        } else {
            url = process.env.REACT_APP_URL_SAVE_REQ_LAB;
        }

        if (coperTeacher.length > 0) {
            myTeachers = coperTeacher;
        } else {
            myTeachers = [{ email: props.apiAuthResponse, partitions: [0] }];
        }

        const payload = (
            {
                idLab: baseImage.idLab,
                email: props.apiAuthResponse,
                //teachers: [{ email: props.apiAuthResponse, partitions: [0] }],
                //teachers: coperTeacher,
                teachers: myTeachers,
                labName: labName,
                labDescription: labDescription,
                labType: labType,
                repoPkgBundleSel: repoPkgBundleSel,
                repoPkgSel: repoPkgSel,
                adSel: adSel,
                labOtherActivities: labOtherActivities,
                labNotes: labNotes,
                baseImage: {
                    os: baseImage.imageOs,
                    name: baseImage.imageName,
                    des: baseImage.imageDes,
                    size: baseImage.imageSize,
                    feed: baseImage.feed,
                    feedBundle: baseImage.feedBundle,
                    imageType: baseImage.imageType,
                    destination: baseImage.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //quota: quota,
                    //schedulerLab: labEvents,
                },
                students: []
            }
        );

        formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }



    const labDescriptionHandler = (event) => {
        setLabDescription(event.target.value);
    }

    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    return (
        <React.Fragment>
            <h2 className="title-page">Richiesta Laboratorio Virtuale</h2>
            <p>Da questa pagina è possibile attivare un Laboratorio Virtuale</p>

            {demoMessage()}

            <Container>
                <Form>
                    <Form.Group style={{ width: "100%" }}>
                        <Form.Label>Nome richiedente</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${props.apiAuthName}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Cognome richiedente</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${props.apiAuthSurname}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>E-mail richiedente</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${props.apiAuthResponse}`}
                            readOnly
                        />
                        <br />
                    </Form.Group>
                </Form>
            </Container>


            <MultipleStepBaseImage
                setBaseImage={setBaseImage}
                baseImage={baseImage}
                setIsBookable={setIsBookable}
                isBookable={isBookable}
            />

            <br />

            {usageType(baseImage)}


        </React.Fragment>
    );

}

export default ReqLabPage;

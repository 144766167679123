import React, { useState, useEffect, useCallback } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BootstrapTable from "react-bootstrap-table-next";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import '../../css/table-sort.css';


const crypto = require("crypto-browserify");


function SelectLib(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [dataLib, setDataLib] = useState([]);
    const [searchLib, setSearchLib] = useState("");
    const [chiave, setChiave] = useState("");
    const [chiaveDes, setChiaveDes] = useState("Selezionare chiave");
    const [isKeySelected, setIsKeySelected] = useState(false);
    const [reloadData, setReloadData] = useState(false);
    const [postazioni, setPostazioni] = useState([]);
    const [numPostazioneSel, setNumPostazioneSel] = useState(0);

    const [myLibs, setMyLibs] = useState([]);

    var ret;

    const searchLibHandler = (lib) => {
        setSearchLib(lib.target.value);
    }

    const selectChiaveNome = (e) => {
        e.preventDefault();
        setChiave("nome");
        setChiaveDes("Nome LIB");
        setIsKeySelected(true);
    }

    const selectChiaveEdificio = (e) => {
        e.preventDefault();
        setChiave("edificio");
        setChiaveDes("Edificio");
        setIsKeySelected(true);
    }

    const selectChiaveSubnet = (e) => {
        e.preventDefault();
        setChiave("subnet");
        setChiaveDes("Subnet");
        setIsKeySelected(true);
    }

    const selectChiaveIp = (e) => {
        e.preventDefault();
        setChiave("ip");
        setChiaveDes("IP");
        setIsKeySelected(true);
    }


    const defineUser = useCallback((computerName, macAddress, ip, userType, labServicesVirtualmachineIdx) => {
        var randomUpperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var randomSpecialChars = '!#$-.';


        var myLabServices;

        if( typeof props.labServicesVirtualmachines === "object" && props.labServicesVirtualmachines.length > 0) {
            myLabServices = props.labServicesVirtualmachines[labServicesVirtualmachineIdx];
        }

        var myUser = {
            //samAccountName: todayStr + crypto.randomBytes(3).toString('hex'),
            email: (computerName + "-" + crypto.randomBytes(6).toString('hex')).slice(0,19),
            quotaConsumed: 0,
            userType: userType,
            password: crypto.randomBytes(7).toString('hex') +
                randomUpperChars.charAt(Math.floor(Math.random() * randomUpperChars.length)) +
                randomSpecialChars.charAt(Math.floor(Math.random() * randomSpecialChars.length)),
            teacher: {
                email: props.apiAuthResponse,
                partition: 0
            },
            postazione: {
                macAddress: macAddress,
                ip: ip,
                computerName: computerName
            },
            labServices: myLabServices
        }

        console.log("debug defineUser: " + JSON.stringify(myUser))

        return myUser;
    }, [props.apiAuthResponse, props.labServicesVirtualmachines])

    useEffect(() => {
        var formBody = [];
        var payload = {
            key: chiave,
            value: searchLib
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_LIBINVENTORY_GET, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataLib(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataLib([])
        setReloadData(false);

        fetchData();

    }, [searchLib, chiave, reloadData]);



    useEffect(() => {
        const lookupPostazioni = async (setStudents) => {
            const localStudents = [];
            const localPostazioni = [];

            console.log("DEBUG allocation mode: " + props.allocationMode)

            if (props.allocationMode === "choose") {
                setPostazioni([]);
                setStudents([]);
            } else {
                setPostazioni([]);
                myLibs.forEach((item) => {
                    // per ciascun LIB selezionato vado a vedere le postazioni
                    var formBodyPostazioni = [];

                    //chiamata API getPostazioni
                    const payloadPostazioni = (
                        { idLabInventory: item }
                    );
                    formBodyPostazioni.push("payload=" + JSON.stringify(payloadPostazioni));

                    setTimeout(async () => {
                        setIsLoading(1);
                        await fetch(process.env.REACT_APP_URL_LIBINVENTORY_GET_POSTAZIONI, {
                            credentials: 'include',
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                            },
                            body: formBodyPostazioni
                        })
                            .then((resp) => {
                                return resp.json();
                            })
                            .then((data) => {
                                if (props.allocationMode === "all" ||
                                    props.allocationMode === "odd" ||
                                    props.allocationMode === "even") {
                                    var i = 0;
                                    var j = 0;
                                    var userType = 'exam';
                                    var myStudent;

                                    if (typeof props.labServicesVirtualmachines === "object" && props.labServicesVirtualmachines.length > 0) {
                                        userType = 'labServices';
                                    }

                                    data.forEach(
                                        (elem) => {
                                            switch (props.allocationMode) {
                                                case "all":
                                                    myStudent = defineUser(elem.computerName, elem.macAddress, elem.ip, userType, j);
                                                    localStudents.push(myStudent);
                                                    localPostazioni.push(elem.computerName);
                                                    j++;
                                                    break;
                                                case "even":
                                                    if (i % 2 === 0) {
                                                        myStudent = defineUser(elem.computerName, elem.macAddress, elem.ip, userType, j);
                                                        localStudents.push(myStudent);
                                                        localPostazioni.push(elem.computerName);
                                                        j++;
                                                    }
                                                    break;
                                                case "odd":
                                                    if (i % 2 !== 0) {
                                                        myStudent = defineUser(elem.computerName, elem.macAddress, elem.ip, userType, j);
                                                        localStudents.push(myStudent);
                                                        localPostazioni.push(elem.computerName);
                                                        j++;
                                                    }
                                                    break;
                                                default:
                                            }
                                            i++;

                                        }
                                    );
                                }
                                console.log("DEBUG numero studenti : " + localStudents.length)
                                setIsLoading(0);
                            })
                            .then(() => {
                                console.log("debug pacchetto studenti: " + localStudents.length)
                                setStudents([].concat(localStudents))
                                setPostazioni([].concat(localPostazioni))
                            })

                            .catch((error) => {
                                console.log(error, "Errore!");
                            });
                    });

                });
            }

        }

        lookupPostazioni(props.setStudents);

    }, [myLibs, defineUser, setPostazioni, props.apiAuthResponse, props.setStudents, props.allocationMode, props.labServicesVirtualmachines]);


    const addExamLib = (idLabInventory) => {
        console.log("debug click add")
        setMyLibs(myLibs.concat([idLabInventory]));
    }

    const delExamLib = (idLabInventory) => {
        console.log("debug click del")
        setMyLibs(myLibs.filter((ele) => { return ele !== idLabInventory; }))
    }

    const onSubmit = (e) => {
        e.preventDefault();
        //var formBody = [];
        //var url;
    }


    const expandRow = {
        renderer: row => (
            <div>
                <p>{`Postazioni del laboratorio ${row.nome}`}</p>
                <BootstrapTable
                    bootstrap4
                    keyField="computerName"
                    data={row.postazioni}
                    columns={columnsPc}
                    striped
                    hover
                    condensed
                    bordered={false}
                    selectRow={selectPostazioni}
                />
            </div>
        ),
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandByColumnOnly: true,
    };


    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        clickToExpand: true,
        hideSelectAll: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                addExamLib(row.idLabInventory);
            } else {
                delExamLib(row.idLabInventory);
            }
        }
    };



    const selectPostazioni = {
        mode: 'checkbox',
        clickToSelect: false,
        clickToExpand: false,
        hideSelectAll: true,
        selected: postazioni,
        isReadOnly: true,
        onSelect: useCallback((row, isSelect, rowIndex, e) => {
            if (props.allocationMode !== "choose") {
                return false;
            } else {
                if (!isSelect) {
                    setNumPostazioneSel(numPostazioneSel-1);
                    setPostazioni(postazioni.filter((ele) => { return ele !== row.computerName }))
                    props.setStudents(props.students.filter((ele) => { return ele.postazione.computerName !== row.computerName }))
                } else {
                    var userType = 'exam';
                    if (typeof props.labServicesVirtualmachines === "object" && props.labServicesVirtualmachines.length > 0) {
                        userType = 'labServices';
                    }                    
                    setPostazioni(postazioni.concat(row.computerName))
                    var myStudent = defineUser(row.computerName, row.macAddress, row.ip, userType, numPostazioneSel);
                    props.setStudents(props.students.concat(myStudent))

                    setNumPostazioneSel(numPostazioneSel+1);
                }
            }
        }, [props, defineUser, postazioni, numPostazioneSel])
    };


    const columnsLib = [
        {
            dataField: "idLabInventory",
            text: "ID LIB",
            sort: true
        },
        {
            dataField: "nome",
            text: "Nome LIB",
            sort: true
        },
        {
            dataField: "edificio",
            text: "Edificio",
            sort: true
        },
        {
            dataField: "subnet",
            text: "Subnet",
            sort: true
        },
        {
            dataField: "ip",
            text: "IP",
            sort: true
        },
    ];

    const columnsPc = [
        {
            dataField: "computerName",
            text: "Computer Name",
            sort: true,
            isKey: true
        },
        {
            dataField: "macAddress",
            text: "MAC Address",
            sort: true
        },
        {
            dataField: "ip",
            text: "IP",
            sort: true
        },
    ];


    if (!dataLib) {
        ret = null;
    } else {
        ret = (
            <React.Fragment>

                <h2 className="title-page">Selezionare i LIB (Laboratori fisici) per lo svolgimento dell'esame </h2>

                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="searchLib" style={{ width: "85%" }}>
                        <Form.Label>Ricerca LIB</Form.Label>
                        <Form.Row>
                            <Col>
                                <Form.Text className="text-muted">Selezionare la chiave</Form.Text>
                            </Col>
                            <Col>
                                <Form.Text className="text-muted">Inserire il Valore</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <DropdownButton id="dropdown-basic-button" title={chiaveDes}>
                                    <Dropdown.Item onClick={selectChiaveNome} >Nome LIB</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveEdificio}>Edificio</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveSubnet}>Subnet</Dropdown.Item>
                                    <Dropdown.Item onClick={selectChiaveIp}>IP</Dropdown.Item>
                                </DropdownButton>
                            </Col>
                            <Col >
                                <Form.Control style={{ width: "100%" }}
                                    required
                                    type="text"
                                    onChange={searchLibHandler}
                                    //placeholder={chiave}
                                    value={searchLib}
                                    readOnly={!isKeySelected}
                                />
                            </Col>
                            <Col >
                                <ClipLoader
                                    size={25}
                                    color={"#a71e3b"}
                                    loading={isLoading}
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>

                <BootstrapTable
                    bootstrap4
                    keyField="idLabInventory"
                    data={dataLib}
                    columns={columnsLib}
                    striped
                    hover
                    condensed
                    bordered={false}
                    expandRow={expandRow}
                    selectRow={selectRow}
                />
            </React.Fragment>
        );
    }



    return ret;

}

export default SelectLib
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Form from 'react-bootstrap/Form';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const { SearchBar } = Search;
const colPkg = [
  {
    dataField: "name",
    text: "Nome pacchetto",
  },
  {
    dataField: "version",
    text: "Versione pacchetto",
  },
  {
    dataField: "description",
    text: "Descrizione pacchetto",
    hidden: true,
  },
];

function SelectRepoPkg(props) {
  const [selOptDisplay, setSelOptDisplay] = useState([]);
  const [selBundleOpt, setSelBundleOpt] = useState([]);
  const [selOpt2, setSelOpt2] = useState([]);
  const [dataPkg, setDataPkg] = useState([]);

  var ret = (<div></div>);




  useEffect(() => {


    const fetchAllData = (feed) => {
      const tableArray = [];
  
      fetch(process.env.REACT_APP_URL_REPO +  
        '?feedName=' + feed +
        '&packageNameQuery=', {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tableArray.push({
              name: `${element.name}`,
              version: `${element.version}`,
              description: `${element.packageDescription}`,
            });
          });
          setDataPkg(tableArray);
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    }
  

    const fetchDefaultData = (feedBundle) => {
      const tempArray = [];
  
      fetch(process.env.REACT_APP_URL_REPO +
        '?feedName=' + feedBundle +
        '&packageNameQuery=', {
        credentials: 'include',
        method: "GET"
      })
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          data.forEach((element) => {
            tempArray.push({
              label: `${element.name}`,
              value: `${element.name}`
            });
          });
          setSelOptDisplay(tempArray.concat(props.installedPackages));
          setSelBundleOpt(tempArray);
          //setSelBundleOpt(tempArray.concat(props.installedPackages));
        })
        .catch((error) => {
          console.log(error, "Errore!")
        });
    }
  
    fetchAllData(props.baseImage.feed);
    fetchDefaultData(props.baseImage.feedBundle);

  }, [props.installedPackages, props.baseImage.feed, props.baseImage.feedBundle]);


  useEffect(() => {
    if (selOpt2 && props.setRepoPkgSel) {
      props.setRepoPkgSel(selOpt2);
    }
    if (selBundleOpt && props.setRepoPkgBundleSel) {
      props.setRepoPkgBundleSel(selBundleOpt);
    }
  })



  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      props.setIsPkgListModified(true);
      var joined = selOptDisplay.concat({
        label: `${row.name}`,
        value: `${row.name}`
      });
      setSelOptDisplay(joined)

      var joinedOpt = selOpt2.concat({
        label: `${row.name}`,
        value: `${row.name}`        
      })
      setSelOpt2(joinedOpt);      
    } else {
      var newArray = [];
      var newArrayOpt = [];

      for (var i = 0; i < selOptDisplay.length; i++) {
        if (selOptDisplay[i].value !== row.name) {
          newArray.push(selOptDisplay[i]);
        }
      }

      selOpt2.forEach(s => {
        if(s.value !== row.name) {
          newArrayOpt.push(s);
        }
      });

      setSelOptDisplay(newArray);
      setSelOpt2(newArrayOpt);
    }

    return true;
  }

  const expandRow = {
    renderer: row => (
      <div>
        <p>{`${row.description}`}</p>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,

    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b>-</b>;
      }
      return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>-</b>
        );
      }
      return (
        <b>...</b>
      );
    },
    expandColumnPosition: 'right'

  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectColumn: true,
    bgColor: '#cc3300',
    onSelect: handleOnSelect,
  };


  const packageSearch = () => {
    var ret;

    if (dataPkg.length > 0) {
      ret = (
        <ToolkitProvider
          keyField="id"
          data={dataPkg}
          columns={colPkg}
          search
        >
          {
            props => (
              <div>
                <SearchBar
                  {...props.searchProps}
                  className="custome-search-field"
                  style={{ color: 'black' }}
                  delay={1000}
                  placeholder="Ricerca pacchetti..."
                />
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  keyField='name'
                  pagination={paginationFactory()}
                  hover={true}
                  expandRow={expandRow}
                  selectRow={selectRow}
                />
              </div>
            )
          }
        </ToolkitProvider>
      )
    } else {
      ret = (
        <p>Nessun pacchetto opzionale disponibile per l'immagine selezionata</p>
      )
    }

    return ret;
  }


  if (selOptDisplay) {
    ret = (

      <React.Fragment >
        <Form.Label>Software richiesto</Form.Label>
        <Form.Text className="text-muted">
          Dati estratti dal repository LIBaaS.
      </Form.Text>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Riepilogo pacchetti selezionati</h5>
            <Select
              isMulti
              value={selOptDisplay}
              isDisabled
            />
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={selOptDisplay}
              required={true}
              readOnly
            />
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Selezione dei pacchetti</h5>

            {packageSearch()}


          </div>
        </div>

        <br />

      </React.Fragment>
    );
  }


  return ret;


}

export default SelectRepoPkg;

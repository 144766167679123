import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from "date-fns/locale/it";
import moment from 'moment';
import 'moment/locale/it';

import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-datepicker/dist/react-datepicker.css';


const localizer = momentLocalizer(moment);


function SchedulerLab(props) {
    const [showModal, setShowModal] = useState(false);
    const [dtInitialStart, setDtInitialStart] = useState();
    const [dtStartEvent, setDtStartEvent] = useState();
    const [dtEndEvent, setDtEndEvent] = useState();


    const [partitions, setPartitions] = useState([]);
    const [selectedPartition, setSelectedPartition] = useState(0);
    const [localEvents, setLocalEvents] = useState([]);

    var ret;

    registerLocale("it", it);

    useEffect(() => {
        var usageData = JSON.parse(props.baseImage.usageData);
        var teachers = JSON.parse(props.baseImage.teachers);
        const evtArray = [];

        usageData.schedulerLab.forEach(element => {
            var myTitle = "";
            if (props.defaultView === 'day') {
                myTitle = 'idLab: ' + element.idLab + ', ' +
                    ' labName: ' + element.labName + ', ' +
                    ' destId: ' + element.destinationId +', ' +
                    ' teacher: ' + element.teacher.email +', ' +
                    ' turno: '
                    + element.teacher.partition
            } else {
                myTitle =  element.teacher.email +
                    ', turno: '
                    + element.teacher.partition
            }
            var a = {
                title: myTitle,
                start: moment(element.start).toDate(),
                end: moment(element.end).toDate(),
                partition: element.teacher.partition,
                teacher: element.teacher.email,
            }

            evtArray.push(a);
        });

        setLocalEvents(evtArray);

        teachers.forEach(teacher => {
            if (teacher.email === props.email) {
                setPartitions(teacher.partitions);
            }
        })

    }, [props.baseImage, props.email, props.defaultView]);



    const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
        if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
        if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
        if (b_start < a_start && a_end < b_end) return true; // a in b
        return false;
    }

    // Selezione evento
    const handleSelect = ({ title, start, end }) => {
        var usageData = JSON.parse(props.baseImage.usageData);
        var countOverlaps = 0;

        localEvents.forEach(localElem => {
            if (dateRangeOverlaps(localElem.start, localElem.end, start, end)) {
                if (!(localElem.start === start && localElem.end === end)) {
                    countOverlaps++;
                }
            }
        })

        if (!props.isSubmitted && (!title || title.includes(props.email))) {
            usageData.schedulerLab.forEach(element => {
                var dtStart = new Date(element.start);
                var dtEnd = new Date(element.end);

                if (element.teacher.email === props.email &&
                    dtStart.valueOf() === start.valueOf() &&
                    dtEnd.valueOf() === end.valueOf()) {
                    setSelectedPartition(element.teacher.partition);
                }
            });

            if (!countOverlaps) {
                setDtStartEvent(start);
                setDtEndEvent(end);
                handleShowModal({ start, end });
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }


    // rimozione di un evento
    const handleDeleteModal = () => {
        const localLabEvents = localEvents;
        const dbEvents = [];

        // Elimino l'elemento selezionato
        const newLocalLabEvents = localLabEvents.filter(function (value, index, arr) {
            return value.start !== dtStartEvent;
        });

        // Popolo l'array da salvare su db
        newLocalLabEvents.forEach(localEvent => {
            var dbEvent = {
                teacher: {
                    email: localEvent.teacher,
                    partition: localEvent.partition,
                },
                start: localEvent.start,
                end: localEvent.end
            };

            dbEvents.push(dbEvent);
        });


        props.setLabEvents(dbEvents)
        setLocalEvents(newLocalLabEvents);

        props.setIsSubmitble(true);
        setShowModal(false);
    };


    // salvataggio di un evento
    const handleSaveModal = () => {
        const dbEvents = [];
        var countOverlaps = 0;


        localEvents.forEach(localElem => {
            if (dateRangeOverlaps(localElem.start, localElem.end, dtStartEvent, dtEndEvent) &&
                localElem.start !== dtInitialStart) {
                countOverlaps++;
            }
        })

        if (!countOverlaps) {
            // Elimino l'elemento selezionato
            const newLocalEvents = localEvents.filter(function (value, index, arr) {
                return value.start !== dtInitialStart;
            });

            // Definisco l'evento corrente da salvere
            var event = {
                title: props.email + ' - ' + selectedPartition,
                start: dtStartEvent,
                end: dtEndEvent,
                partition: selectedPartition,
                teacher: props.email,
            }

            // Aggiungo l'evento all'array locale
            newLocalEvents.push(event);
            setLocalEvents(newLocalEvents)

            // Aggiungo l'evento all'array da salvare su db
            newLocalEvents.forEach(localEvent => {
                var dbEvent = {
                    teacher: {
                        //email: myTeacher,
                        email: localEvent.teacher,
                        partition: localEvent.partition,
                    },
                    start: localEvent.start,
                    end: localEvent.end
                };

                dbEvents.push(dbEvent);
            });

            props.setLabEvents(dbEvents);
            props.setIsSubmitble(true);
        }

        setShowModal(false);
    };


    // Input evento
    const handleShowModal = ({ start, end }) => {
        setDtInitialStart(start);
        setShowModal(true);
    };

    const modalEvent = () => {
        if (!showModal) return;

        var retModal;

        retModal = (
            <Modal
                className={'modal-dialog-centered'}
                show={showModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Prenotazione Virtual Lab</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group controlId="ModalForm.ScheduleEvent1">
                            <Form.Label>Docente</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={props.email}
                                readOnly
                            />
                            <Form.Label>Laboratorio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={props.baseImage.imageDes}
                                readOnly
                            />
                            <Form.Label>Definizione evento</Form.Label>
                            <Form.Row>
                                <Col>
                                    <Form.Text className="text-muted">Inizio</Form.Text>
                                </Col>
                                <Col>
                                    <Form.Text className="text-muted">Fine</Form.Text>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col>
                                    <DatePicker
                                        selected={dtStartEvent}
                                        onChange={date => setDtStartEvent(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="dd/mm/yyyy HH:mm"
                                        locale="it"
                                        inline
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={dtEndEvent}
                                        onChange={date => setDtEndEvent(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="dd/mm/yyyy HH:mm"
                                        locale="it"
                                        inline
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group controlId="ModalForm.ScheduleEvent1">
                            <Form.Label>Turno</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                defaultValue={selectedPartition}
                                onChange={(event) => {
                                    setSelectedPartition(event.target.value);
                                }}
                            >
                                {
                                    partitions.map((option, index) => {
                                        return (<option key={index} value={option}>{option}</option>)
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteModal}>
                        Elimina
                    </Button>
                    <Button variant="primary" onClick={handleSaveModal}>
                        Inserisci prenotazione
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return retModal;

    }


    ret = (
        <React.Fragment>
            <h3>Pianificazione</h3>
            {modalEvent()}
            <Calendar
                selectable={!props.isSubmitted}
                defaultView={props.defaultView}
                //defaultDate={new Date()}
                defaultDate={props.defaultDate}
                scrollToTime={new Date()}
                localizer={localizer}
                //events={props.labEvents}
                events={localEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 850 }}
                onSelectSlot={handleSelect}
                onSelectEvent={handleSelect}
                //                onSelectEvent={event => alert(event.title)}
                messages={{
                    next: "Prossimo",
                    previous: "Precedente",
                    today: "Oggi",
                    month: "Mese",
                    week: "Settimana",
                    day: "Giorno"
                }}

            />

        </React.Fragment>
    );

    return ret;
}

export default SchedulerLab;
import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import BootstrapTable from "react-bootstrap-table-next";
import ReactDatePicker from "react-datepicker";
import ClipLoader from "react-spinners/ClipLoader";
import SchedulerLab from '../scheduleLab/SchedulerLab';

import '../../css/table-sort.css';


const labColumnsAdmin = [
    {
        dataField: "email",
        text: "email",
        hidden: false,
        sort: true
    },
    {
        dataField: "idLab",
        text: "Identificativo",
        sort: true
    },
    {
        dataField: "labName",
        text: "Nome Laboratorio",
        sort: true
    }
];


function LabConsolleSchedulePage(props) {
    const [labData, setLabData] = useState();
    const [labSchedule, setLabSchedule] = useState();
    const [isLoading, setIsLoading] = useState(1)
    const [startDate, setStartDate] = useState(new Date());
    const [labEvents, setLabEvents] = useState([]);
    const [isSubmitble, setIsSubmitble] = useState(false);


    useEffect(() => {
        var formBody = [];

        function addDays(date, days) {
            var result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        }

        var myStartDate = new Date(startDate.toDateString()).toISOString();
        var myEndDate = addDays(startDate, 15)
        myEndDate = new Date(myEndDate.toDateString()).toISOString();

        const payload = {
            startDate: myStartDate,     //"2021-10-04T12:00:00.000Z",
            endDate: myEndDate,         //"2021-10-04T23:00:00.000Z",
        }

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            setIsLoading(1);
            var myUrl = process.env.REACT_APP_URL_GET_SCHEDULE_ALL_LAB;

            await fetch(myUrl, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    const allSchedulerLab = [];
                    const allTeachers = [];

                    data.forEach(elem => {
                        elem.usageData.schedulerLab.forEach(s => {
                            var s2 = {
                                teacher: s.teacher,
                                start: s.start,
                                end: s.end,
                                idLab: elem.idLab,
                                labName: elem.labName,
                                destinationId: elem.baseImage.destination.id,
                            }
                            allSchedulerLab.push(s2);
                        })
                        elem.teachers.forEach(t => {
                            allTeachers.push(t)
                        })

                    })

                    var allUsageData = {
                        usageData: JSON.stringify({
                            schedulerLab: allSchedulerLab,
                        }),
                        teachers: JSON.stringify(allTeachers)
                    }

                    setLabSchedule(allUsageData);
                    setLabData(data);

                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
            setIsLoading(0);
        });

    }, [props, startDate]);


    const searchForm = () => {
        var ret;

        if (props.isAdmin) {
            ret = (
                <Form>
                    <Form.Group controlId="emailSelector" style={{ width: "85%" }}>
                        <Form.Label>Vista pianificazioni</Form.Label>
                        <Form.Row>
                            <Col>
                                <Form.Text className="text-muted">Data inizio pianificazione</Form.Text>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <ReactDatePicker
                                    dateFormat="dd MMMM yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    popperPlacement="top-end"
                                />
                            </Col>
                        </Form.Row>
                    </Form.Group>
                </Form>

            );
        }

        return ret;
    }

    const isLoadingPage = () => {
        var retLoadingPage = null;

        if (isLoading) {
            retLoadingPage = (
                <React.Fragment>
                    <ClipLoader
                        size={300}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </React.Fragment>
            )
        }

        return retLoadingPage;
    }

    const resultSchedule = () => {
        var ret = null;

        if(isSubmitble) {
            //console.log("todo");
        }

        if (!isLoading) {
            ret = (
                <React.Fragment>
                    <BootstrapTable
                        bootstrap4
                        keyField="idLab"
                        data={labData}
                        columns={labColumnsAdmin}
                        striped
                        hover
                        condensed
                        bordered={false}
                    />

                    <SchedulerLab
                        email={props.apiAuthResponse}
                        baseImage={labSchedule}
                        labEvents={labEvents}
                        setLabEvents={setLabEvents}
                        defaultView={'day'}
                        defaultDate={startDate}
                        setIsSubmitble={setIsSubmitble}
                        isSubmitted={0}                        
                    />

                </React.Fragment>
            );
        }

        return ret;
    }


    const pubLabPage = () => {
        var retPub = null;

        retPub = (
            <React.Fragment>
                <h2 className="title-page">Vista pianificazione Laboratori Virtuali</h2>
                <p>Questa pagina offre una visione complessiva delle schedulazioni a partire dalla data indicata</p>

                {searchForm()}
                {isLoadingPage()}
                {resultSchedule()}

            </React.Fragment>
        );

        return retPub;
    }

    var ret = (
        <React.Fragment>
            {pubLabPage()}
        </React.Fragment>
    );

    if (labData) {
        return ret;
    } else {
        return null;
    }


}

export default LabConsolleSchedulePage;

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button"
import ClipLoader from 'react-spinners/ClipLoader';
import SchedulerLab from '../scheduleLab/SchedulerLab'
import ReqLabConfirm from '../scheduleLab/ReqLabConfirm';

import '../../css/table-sort.css';



function LabConsolleScheduleDettPage(props) {
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [labEvents, setLabEvents] = useState([]);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [statusRequest, setStatusRequest] = useState();

    var ret = null;

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var url;

        url = process.env.REACT_APP_URL_BOOK_LAB;

        const payload = (
            {
                idLab: props.labProps.idLab,
                email: props.labProps.email,
                baseImage: {
                    os: props.labProps.imageOs,
                    name: props.labProps.imageName,
                    des: props.labProps.imageDes,
                    size: props.labProps.imageSize,
                    feed: props.labProps.imageFeed,
                    feedBundle: props.labProps.imageFeedBundle,
                    imageType: props.labProps.imageType,
                    destination: props.labProps.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //                    quota: quota,
                    schedulerLab: labEvents,
                }
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    ret = (
        <React.Fragment>
            <h2 className="title-page">Pianificazione Laboratorio Virtuale {props.labProps.labName}</h2>

            <Button
                size="sm"
                variant="primary"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>
            <br />
            <hr />

            <Form onSubmit={onSubmit}>
                <Form.Group style={{ width: "100%" }}>
                    <Form.Label>E-mail richiedente</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.email}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Identificativo Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.idLab}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Tipologia Laboratorio</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.imageType}`}
                        readOnly
                    />
                    <br />

                    <Form.Label>Sistema Operativo</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.imageOs}`}
                        readOnly
                    />
                    <br />
                    <Form.Label>Immagine base</Form.Label>
                    <Form.Control style={{ width: "100%" }}
                        type="text"
                        placeholder={`${props.labProps.imageName}`}
                        readOnly
                    />
                    <br />
                </Form.Group>



                <SchedulerLab
                    email={props.apiAuthResponse}
                    baseImage={props.labProps}
                    labEvents={labEvents}
                    setLabEvents={setLabEvents}
                    setIsSubmitble={setIsSubmitble}
                    isSubmitted={isSubmitted}
                    defaultView={'week'}
                    defaultDate={new Date()}
                />
                <br />
                <div>
                    <ReqLabConfirm
                        isSubmitted={isSubmitted}
                        isSubmitble={isSubmitble}
                        statusRequest={statusRequest}
                        apiAuthGroups={props.apiAuthGroups}
                        buttonDescription={"Salva pianificazione"}
                    />
                    <br />
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </div>

            </Form>

            <hr />
            <Button
                size="sm"
                variant="primary"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>
        </React.Fragment>
    );





    if (props.labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="primary"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>
        );
    }

}

export default LabConsolleScheduleDettPage;
